@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.contact-container {
  // position: absolute;
  margin-top: 150px;
  color: white;
  margin-left: 25%;
  width: 50%;
  animation: fadein 2s;
  @media (max-width: 500px) {
    width: 80%;
    margin-left: 10%;
  }

  .form-top {
    display: flex;
    flex-direction: row;

    @media (max-width: 500px) {
      flex-direction: column-reverse;
    }
  }

  .contact-links {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    // width: 100%;
    margin-left: 20%;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 50px;
      font-size: 3rem;

      i {
        font-size: 6rem;

        &:hover {
          font-size: 6.4rem;
          cursor: pointer;
        }
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;

    input {
      margin-top: 20px;
      height: 3rem;
      padding: 5px;
      border: none;
      border-radius: 3px;
      width: 100%;
      //   margin-left: 25%;
    }

    .message {
      margin-top: 20px;
      padding: 5px;
      border-radius: 3px;
      border: none;
      width: 100%;
      margin-left: 0;
      height: 200px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    }

    button {
      margin-top: 20px;
      width: 100px;
      border: none;
      border-radius: 3px;
      background: rgb(160, 14, 14);
      color: white;
      height: 40px;

      &:hover {
        cursor: pointer;
        background: rgb(233, 24, 24);
      }
    }
  }
}
