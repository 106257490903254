@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home-container {
  // position: absolute;
  margin-top: 10%;
  color: white;
  margin-left: 15%;
  animation: fadein 2s;

  h1 {
    font-size: 6rem;
    font-weight: bold;
    margin-bottom: 50px;
    @media (max-width: 500px) {
      margin-top: 100px;
      font-size: 4rem;
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 2.1rem;
    width: 80%;
    line-height: 3rem;
    margin-bottom: 25px;
    @media (max-width: 500px) {
      line-height: 3.5rem;
    }
  }

  button {
    width: 200px;
    height: 50px;
    margin-right: 20px;
    border: none;
    border-radius: 3px;
    color: white;
    font-size: 1.9rem;
    line-height: 1.2rem;
    background: grey;
    margin-top: 10px;
  }

  button:hover {
    cursor: pointer;
    background: rgb(173, 173, 173);
  }

  .red-button {
    background: rgb(160, 14, 14);

    &:hover {
      background: rgb(233, 24, 24);
    }
  }
}
