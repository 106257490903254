@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header {
  margin-top: 20px;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  height: 80px;
  // position: absolute;
  display: flex;
  justify-content: space-between;
  width: 90%;
  animation: fadein 2s;
  //   background: blue;
  @media (max-width: 500px) {
    display: block;
  }

  .logo-container {
    display: flex;
    width: 400px;
    justify-content: space-evenly;
    margin-left: 50px;
    // border: 1px solid white;
    @media (max-width: 500px) {
      margin-left: 0;
      width: 100%;
    }

    img {
      width: 75px;
      height: 75px;
      margin-top: 5px;
    }

    p {
      color: rgb(194, 28, 28);
      font-size: 6rem;
      line-height: 4rem;
      margin-top: 10px;
    }

    .title-container {
      color: white;

      h3 {
        font-size: 3rem;
        margin-top: 7px;
        margin-bottom: 0;
        font-weight: bold;
      }

      h5 {
        margin-top: 0;
        font-size: 2rem;
      }
    }
  }

  .nav {
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin-right: 100px;
    // background: green;
    @media (max-width: 500px) {
      width: 100%;
      margin-left: 5%;
    }

    a {
      color: white;
      text-decoration: none;
      font-size: 2.1rem;
      padding-top: 30px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
