@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.projects {
  // position: absolute;
  margin-top: 80px;
  color: white;
  margin-left: 8%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  animation: fadein 2s;

  @media (max-width: 500px) {
    display: block;
    width: 100%;
    margin-left: 0;
  }

  .project-container {
    width: 100%;

    @media (max-width: 500px) {
      border-bottom: 1px solid gray;
      // margin-left: 10%;
      padding-left: 10%;
      padding-bottom: 10px;
    }

    h3 {
      width: 80%;
      text-align: center;
      font-size: 2.4rem;
      margin-top: 10px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .project-buttons {
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      a {
        width: 30%;
        height: 30px;
        border: none;
        border-radius: 3px;
        background: rgb(160, 14, 14);
        color: white;
        text-align: center;
        margin-bottom: 20px;

        &:hover {
          background: rgb(233, 24, 24);
          cursor: pointer;
        }

        button {
          background: none;
          color: white;
          border: none;
          width: 100%;
          height: 30px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    img {
      width: 80%;
      height: auto;
      border-radius: 5px;

      @media (max-width: 500px) {
        margin-top: 20px;
      }
    }

    p {
      width: 80%;
      font-size: 1.7rem;
      margin-bottom: 20px;
    }

    ul {
      list-style-type: disc;
      margin-left: 40px;
      li {
        width: 70%;
        margin-bottom: 10px;
        font-size: 1.7rem;
      }
    }
  }
}
