@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.footer-container {
  // position: absolute;
  //   margin-top: 38%;
  // position: fixed;
  // bottom: 0;
  margin-top: 160px;
  color: white;
  width: 100%;
  margin-bottom: 5px;
  animation: fadein 2s;

  @media (max-width: 500px) {
    margin-top: 100px;
  }

  h3 {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .skill-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    font-size: 2.5rem;

    @media (max-width: 500px) {
      font-size: 2rem;
    }
    // align-items: center;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 500px) {
        // margin-right: 10px;
        margin-bottom: 10px;
      }

      i {
        // margin-left: 10px;
        font-size: 6.5rem;
        margin-top: 10px;

        @media (max-width: 500px) {
          margin-right: 10px;
        }
      }
    }
  }

  //   .icon-container {
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: space-evenly;
  //     font-size: 2rem;
  //   }
}
